<template>
  <div class="app">
    <Navbar :title="$t('page.withdrawRecord')" />
    <!-- <van-nav-bar title="withdraw-record">
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
    </van-nav-bar> -->
    <div class="tab-lists">
      <div
        class="tab-item"
        :class="[status === item.params.status ? 'on-tab-item' : '']"
        v-for="(item, index) in tabLists"
        :key="index"
        @click="handleTabItme(item.params.status)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="wrap">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="No more..."
        loading-text="loading"
        @load="onLoad"
      >
        <!-- <scroll-view class="list" scroll-y @scrolltolower="handleLoadMore"> -->
        <div class="list-item" v-for="(item, index) in lists" :key="index">
          <div class="list-item-left">
            <div class="list-item-title">
              <span class="list-item-title-text">{{ item.bankinfo.name }}</span>
              <!-- <text v-if="item.status==='已汇款'" class="list-item-title-sub">（{{stateObj[item.status]}}）</text> -->
              <span v-if="item.status === '已汇款'" class="list-item-title-sub"
                >（{{ stateObj[item.status] }}）</span
              >
              <!-- <text v-else class="list-item-title-sub-green">（{{stateObj[item.status]}}）</text> -->
              <span v-else class="list-item-title-sub-green"
                >（{{
                  item.retrun == "1"
                    ? "Failed-balance returned"
                    : stateObj[item.status]
                }}）</span
              >
            </div>
            <!-- language-ch 原因： -->
            <span
              v-if="item.status === '未通过' && item.reason"
              class="list-item-reason"
              >Reason：{{ item.reason }}</span
            >
            <!-- language-ch 尾号 -->
            <span class="list-item-bank" v-if="item.coin_type === ''"
              >{{ item.bankinfo.bank }} tail number
              {{ item.bankinfo.card_l4 }}</span
            >
            <span class="list-item-bank" v-if="item.coin_type !== ''">{{
              `${$t("newAdd.Amount")}: ${item.coin_num}${item.coin_type}`
            }}</span>
            <div class="list-item-indate">{{ dateEvent(item.indate) }}</div>
          </div>
          <div class="list-item-right">
            <!-- language-ch 订单ID： -->
            <!-- <span class="list-item-order">{{$t('newAdd.OrderID')}}：{{ item.id }}</span> -->
            <span class="list-item-money">{{ item.money }}</span>
          </div>
        </div>
        <!-- language-ch 没有任何记录 -->
        <!-- <div v-if="alreadyLastPage" class="nolist">
        There's no record of anything
      </div> -->
        <!-- </scroll-view> -->
      </van-list>
    </div>
  </div>
</template>

<script>
import { NavBar, cell, icon, field, Dialog, Toast, List } from "vant";
import { GET_WITHDRAW_LISTS } from "@/api";
import { formatDate } from "@/utils/tools";
import Navbar from "@/components/JNav";
export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [List.name]: List,
    Navbar,
  },
  data() {
    return {
      loading: false,
      finished: false,
      status: 0,
      alreadyLastPage: false,
      pages: 1,
      lists: [],
      tabLists: [
        {
          // language-ch 全部
          title: this.$t("Cart.state.all"),
          params: { status: 0 },
        },
        {
          // language-ch 审核中
          title: this.$t("newAdd.reviewing"),
          params: { status: 1 },
        },
        {
          // language-ch 已汇款
          title: this.$t("newAdd.remittance"),
          params: { status: 4 },
        },
        {
          // language-ch 未通过
          title: this.$t("newAdd.notThrough"),
          params: { status: 5 },
        },
      ],
      stateObj: {
        已汇款: "success",
        未通过: "not pass",
        汇款中: "processing",
        idpay: "Pay on behalf of",
      },
    };
  },
  mounted() {},
  methods: {
    onLoad() {
      this.getLists();
    },
    handleTabItme(status) {
      this.status = status;
      this.alreadyLastPage = false;
      this.pages = 1;
      this.lists = [];
      this.loading = false;
      this.finished = false;
      this.getLists();
    },
    handleLoadMore() {
      if (!this.alreadyLastPage) {
        this.getLists();
      }
    },
    getLists() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_WITHDRAW_LISTS({
        status: this.status,
        page: this.pages,
      })
        .then((r) => {
          Toast.clear();
          this.loading = false;
          if (r.data.ret === 1) {
            Toast.clear();
            let dataLists = r.data.data.lists;
            if (r.data.data.currentPage > 1) {
              Toast.clear();
              this.pages++;
              for (let i = 0; i < dataLists.length; i++) {
                this.lists.push(dataLists[i]);
              }
              if (r.data.data.currentPage === this.pages) {
                this.finished = true;
                this.loading = false;
              } else {
                this.finished = false;
                this.loading = true;
              }
            } else {
              Toast.clear();
              this.lists = dataLists;
              this.finished = true;
              this.loading = false;
            }
            this.alreadyLastPage = this.pages === 1 && dataLists.length === 0;
          } else {
            Toast.clear();
            this.$toast(r.data.msg);
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          Toast.clear();
          this.loading = false;
          this.loading = false;
          this.finished = true;
          console.error(e);
        });
    },
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
  },
};
</script>

<style scoped lang="less">
@Color: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: #07304a;
@comBG: var(--bg1);
.app {
  flex: 1;
  width: 100%;
  height: 100vh;
  background: var(--bg);
  > .tab-lists {
    display: flex;
    align-items: center;
    // background-color: #fff;
    background: var(--bg2);
    // border-bottom: calc(1rem / 16) solid #f4f8f9;
    > .tab-item {
      width: 25%;
      height: calc(40rem / 16);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(12rem / 16);
      color: var(--gray1);
    }
    > .on-tab-item {
      // color: #dc1323;
      color: var(--theme);
      font-weight: bold;
    }
  }
}
.wrap {
  height: calc(100vh - (46rem / 16) - (40rem / 16));
}
.list {
  flex: 1;
  width: 100%;
  height: calc(100vh - (41rem / 16) - (44rem / 16));
  background-color: #ffffff;
}
.nolist {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  padding-top: calc(25rem / 16);
  padding-bottom: calc(25rem / 16);
  text-align: center;
  padding-bottom: calc(12rem / 16);
  color: #999999;
  margin: auto;
}
.list-item {
  // width: 100%;
  padding: calc(20rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: calc(1rem / 16);
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
}
.list-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list-item-title-text {
  font-size: calc(23rem / 16);
  color: #242424;
}
.list-item-title-sub,
.list-item-title-sub-green {
  font-size: calc(12rem / 16);
  color: #1bb019;
}
.list-item-title-sub-green {
  color: #dc1323;
}
.list-item-reason {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  color: #ff6e1c;
  width: calc(520rem / 16);
}
.list-item-bank {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  // color: #888888;
  color: var(--gray1);
}
.list-item-indate {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  // color: #888888;
  color: var(--gray1);
}
.list-item-right {
  align-items: flex-end;
}
.list-item-order {
  font-size: calc(10rem / 16);
  // color: #999999;
  color: var(--gray1);
}
.list-item-money {
  margin-top: calc(6rem / 16);
  font-size: calc(17rem / 16);
  color: #dc1323;
}
</style>